// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-contact-template-js": () => import("./../src/templates/ContactTemplate.js" /* webpackChunkName: "component---src-templates-contact-template-js" */),
  "component---src-templates-no-index-template-js": () => import("./../src/templates/NoIndexTemplate.js" /* webpackChunkName: "component---src-templates-no-index-template-js" */),
  "component---src-templates-people-template-js": () => import("./../src/templates/PeopleTemplate.js" /* webpackChunkName: "component---src-templates-people-template-js" */),
  "component---src-templates-thankyou-template-js": () => import("./../src/templates/ThankyouTemplate.js" /* webpackChunkName: "component---src-templates-thankyou-template-js" */),
  "component---src-templates-homepage-template-js": () => import("./../src/templates/HomepageTemplate.js" /* webpackChunkName: "component---src-templates-homepage-template-js" */),
  "component---src-templates-the-firm-template-js": () => import("./../src/templates/TheFirmTemplate.js" /* webpackChunkName: "component---src-templates-the-firm-template-js" */),
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */)
}

